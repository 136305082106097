import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFile, faFileInvoice, faPenSquare, faSignInAlt, faSignOutAlt, faHome, faCheckCircle, faUndo, faSlidersH, faEdit, faSave, faBook,faHourglassHalf,faEye,faDownload,faTrashAlt,faCaretSquareDown } from '@fortawesome/free-solid-svg-icons'
// import { faFile } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFile, faFileInvoice, faPenSquare, faSignInAlt, faSignOutAlt, faHome, faCheckCircle, faUndo, faSlidersH, faEdit, faSave, faBook,faHourglassHalf,faEye,faDownload,faTrashAlt,faCaretSquareDown)

const app = createApp(App).use(store).use(router)

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')

