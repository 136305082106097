<template>
  <div class="nav-bar">
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark bg-nav">
        <div class="container-fluid">
          <router-link class="navbar-brand" to="/">
            Registration-Documents</router-link
          >
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  เลือกประเภทเอกสาร
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <router-link class="dropdown-item" to="/register1"
                      >ขอ ปพ.1</router-link
                    >
                  </li>
                  <li>
                    <router-link class="dropdown-item" to="/register2"
                      >แก้ผลการเรียน</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>

            <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
              <li class="nav-item me-md-2 mb-2" v-show="status">
                <button class="btn btn-light" @click="dashboard">
                  <font-awesome-icon icon="home" /> หน้าหลัก
                </button>
              </li>
              <li class="nav-item" v-if="!status">
                <button class="btn btn-warning" @click="admin">
                  <font-awesome-icon icon="sign-in-alt" /> สำหรับผู้ดูแลระบบ
                </button>
              </li>
              <li class="nav-item" v-else>
                <button class="btn btn-warning" @click="sign_out">
                  <font-awesome-icon icon="sign-out-alt" /> ออกจากระบบ
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import store from "../store"; // your vuex store ,

export default {
  name: "Navbar",
  data: function () {
    return {
      status: store.getters["isAuthenticated"],
    };
  },
  methods: {
    admin: function () {
      this.$router.push("/admin");
    },
    sign_out: function () {
      window.localStorage.removeItem("token");
      this.status = false;
      this.$store.state.token = null;
      this.$router.push("/admin");
    },
    dashboard: function () {
      this.$router.push("/dashboard");
    },
  },
};
</script>

<style scoped>
.bg-nav {
  background-image: linear-gradient(
    to right,
    #6a11cb 0%,
    #2575fc 100%
  ) !important;
}
</style>
