import { createStore } from 'vuex'

export default createStore({
  state: {
    pdf: null,
    title: null,
    form: "",
    token: localStorage.getItem('token') || null

  },
  getters: {
    isAuthenticated: state => {
      if (state.token == "d384aef903aeaa8032dc0aa0c418954c") {
        return true
      }
      else {
        return false
      }
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
