<template>
  <div class="home">
    <Navbar />
    <section class="mb-5 pt-5">
      <div class="container">
        <HeadSection txt="Registration Documents - โรงเรียนโพนงามพิทยานุกูล"/>
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="bg-card rounded shadow h-100 cursor" @click="register1">
              <div class="p-3 text-white text-center">
                <h1>
                  <font-awesome-icon icon="file-invoice" />
                </h1>
                <h5>คำร้องขอใบระเบียนแสดงผลการเรียน (รบ.1/ปพ.1)</h5>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="bg-card2 rounded shadow h-100 cursor"
              @click="register2"
            >
              <div class="p-3 text-white text-center">
                <h1>
                  <font-awesome-icon icon="pen-square" />
                </h1>
                <h5>คำร้องขอแก้ผลการเรียน</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import HeadSection from '@/components/HeadSection.vue'
import firebase from '../db'
import "firebase/app-check";
const appCheck = firebase.appCheck();
appCheck.activate("6LcgB7sbAAAAAOVWWbCItQoJWdVpPqAD1KTnIqDa");

export default {
  name: "Home",
  components: {
    Navbar,
    HeadSection,
  },
  methods: {
    register1: function () {
      this.$router.push("register1");
    },
    register2: function () {
      this.$router.push("register2");
    },
  },
};
</script>
<style scoped>
.bg-card {
  background: #56ccf2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2f80ed,
    #56ccf2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2f80ed,
    #56ccf2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-card2 {
  background: #ee0979; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ff6a00,
    #ee0979
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ff6a00,
    #ee0979
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
