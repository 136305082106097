import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { nextTick } from 'vue'
import store from '../store'


const ifAuthenticated = (to, form, next) => {
  if (store.getters['isAuthenticated']) {
    next()
    return
  }
  next({ path: '/admin' })
}

const ifNotAuthenticated = (to, form, next) => {
  if (!store.getters['isAuthenticated']) {
    next()
    return
  }
  next({ path: '/dashboard' })
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'หน้าแรก' },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { title: 'เกี่ยวกับ' },
  },
  {
    path: '/register1',
    name: 'Register1',
    component: () => import('../views/Register1.vue'),
    meta: { title: 'คำร้องขอ ปพ.1' },
  },
  {
    path: '/register2',
    name: 'Register2',
    component: () => import('../views/Register2.vue'),
    meta: { title: 'คำร้องขอแก้ผลการเรียน' },
  },
  {
    path: '/admin',
    name: "Admin",
    component: () => import('../views/Admin.vue'),
    meta: { title: 'เข้าสู่ระบบ' },
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/dashboard',
    name: "Dashboard",
    meta: { title: 'หน้าหลัก' },
    component: () => import('../views/Dashboard.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path:"",
        name:"DashboardHome",
        component:()=>import('../components/DashboardHome.vue')
      },
      {
        path: "documents",
        name: "DashboardDoc",
        component: () => import('../components/DashboardDoc.vue')
      }
    ]
  },
  {
    path:"/preview/:param",
    name:"PreviewPDF",
    meta: { title: 'ดูตัวอย่าง' },
    component:()=>import('../views/PreviewPDF.vue')
  },
  {
    path:"/download/:param",
    name:"DownloadPDF",
    meta: { title: 'ดาวน์โหลด' },
    component:()=>import('../views/DownloadPDF.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: "NotFound",
    meta: { title: 'Not Found' },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  hash: false
})

const DEFAULT_TITLE = 'Official Document';

router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title + " | " + DEFAULT_TITLE || DEFAULT_TITLE;
  })

  return false
})

export default router
