<template>
  <div class="head-section">
    <div class="row justify-content-center">
      <div class="col-md-2 col-4">
        <img id="logo" class="img-fluid" :src="require('@/assets/logo.png')"/>
      </div>
    </div>
    <h3 class="text-center pt-4">
      {{txt}}
    </h3>
    <hr />
  </div>
</template>

<script>

export default {
  name: "HeadSection",
  props: ['txt'],
};
</script>

<style>
</style>